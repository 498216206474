import { useState } from 'react';
import I18n from './helpers/I18n';
import { PrizeCards } from './PrizeCards';
import { PrizeRules } from './PrizeRules';
import logo from '../assets/crave_logo.svg';


function Prizes() {

  const [displayRules, setDisplayRules] = useState(false);
  const toggleOpen = () => {
    setDisplayRules(!displayRules);
  };

  const renderPrizes = () => {
    return (
      <>
        <div className="PrizesPage__head">
          <img
            className="PrizesPage__head_logo"
            src={logo}
            alt="crave_logo"
          />
        </div>

        <div className="PrizesPage__body">
          <div className="PrizesPage__body_title">
            <I18n t="client.prizes.title" />
          </div>
          <div className='PrizesPage__body_content'>
            <I18n t="client.prizes.content" />
          </div>
          <PrizeCards />
        </div>
        <div className="PrizesPage__rules" >
          <div onClick={toggleOpen}>
            <I18n t="client.prizes.rules" />
          </div>
        </div>
      </>
    )
  };

  const renderRules = () => {
    return <PrizeRules handleClose={toggleOpen} />
  }

  const renderContent = () => {
    if (displayRules) {
      return renderRules();
    } else {
      return renderPrizes();
    }
  };

  return (
    <div className="PrizesPage">

      {renderContent()}
      <div className='PrizesPage__footer'>
        <I18n t="client.prizes.footer" />
      </div>
    </div>
  );
}

export default Prizes;
