import { useState } from "react";
import { translate } from "./I18n";

const DEFAULT_URL = process.env.REACT_APP_API_URL || 'https://api.lvh.me';

const Form = ({ prizeName, locked }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(locked);
  const [error, setError] = useState(false);

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };


  const handleInputName = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    setError(false);

    const body = new FormData();
    body.append("prizeName", prizeName);
    body.append("email", email);
    body.append("name", name);

    localStorage.setItem('prize', prizeName);

    fetch(`${DEFAULT_URL}/redeem`, {
      method: "POST",
      body
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "success") {
          setLoading(false);
          setFinished(true);
        } else {
          setLoading(false);
          setError(true);
        }
      });
  };

  if (finished) {
    return <div className="PrizesPage__finished">Finished</div>;
  }

  if (loading) {
    return <div className="PrizesPage__loading">Loading...</div>;
  }

  return (
    <form className="PrizesPage__form" onSubmit={handleSubmit}>
      <input
        className="PrizesPage__form-input"
        type="name"
        name="name"
        autoComplete="off"
        value={name}
        placeholder={translate("en-CA", "client.prizes.placeholder.name")}
        onChange={handleInputName}
        required
      />

      <input
        className="PrizesPage__form-input"
        type="email"
        name="email"
        autoComplete="off"
        value={email}
        placeholder={translate("en-CA", "client.prizes.placeholder.email")}
        onChange={handleInputEmail}
        required
      />

      <button type="submit" className="PrizesPage__form-button">
        {translate("en-CA", "client.prizes.button")}
      </button>

      {error && (
        <div className="PrizesPage__form-errors">
          {translate("en-CA", "client.prizes.error")}
        </div>
      )}
    </form>
  );
};

export default Form;
