import translations from "../../translations";

const translate = (locale, key, params = {}) => {
  try {
    let translation = key.split(".").reduce((root, key) => {
      return root[key];
    }, translations[locale]);

    Object.entries(params).forEach(([param, value]) => {
      translation = translation.split(`{${param}}`).join(value);
    });

    return translation;
  } catch (e) {
    console.info(`Missing translation: ${locale}.${key}`);

    return "";
  }
};

const I18n = ({ t, l = null, DOMElement = "p", params = {} }) => {
  // const lang = useSelector((state) => state.stateMachine.lang);
  const text = translate(l || "en-CA", t, params);

  if (text && text.indexOf("<>") === 0) {
    return (
      <DOMElement
        dangerouslySetInnerHTML={{
          __html: text.replace("<>", ""),
        }}
      ></DOMElement>
    );
  }

  return <>{text}</>;
};

export { translate };

export default I18n;
