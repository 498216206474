import { useState } from 'react';
import I18n, { translate } from './helpers/I18n';
import Form from './helpers/Form';
import Tiff from '../assets/Tiff-Logo.png';
import Crave from '../assets/Crave-Logo.png';
import Bell from "../assets/Bitmap.png";

export const PrizeCards = () => {
  const sessionPrize = localStorage.getItem('prize') || null;

  const locked = sessionPrize !== null

  const [selectedPrize, setSelectedPrize] = useState(sessionPrize)
  const prizeDetails = [{
    name: 'Crave',
    img: Crave,
    title: <I18n t="client.prizes.items.Crave.title" />,
    content: <I18n t="client.prizes.items.Crave.content" />,

  },
  {
    name: 'Tiff',
    img: Tiff,
    title: <I18n t="client.prizes.items.Tiff.title" />,
    content: <I18n t="client.prizes.items.Tiff.content" />,

  },
  {
    name: 'Bell',
    img: Bell,
    title: <I18n t="client.prizes.items.Bell.title" />,
    content: <I18n t="client.prizes.items.Bell.content" />,
  }]


  const handleSelect = (prizeName) =>{
    setSelectedPrize(prizeName);
  }

  const renderPrize = (prize, form = false) => {
    return (
      <div key={prize.name} className="PrizeCards__card">
        <img className={`PrizeCards__card_img PrizeCards__card_img_${prize.name}`} src={prize.img} alt={prize.name} />
        <div className="PrizeCards__card_title">
          {prize.title}
        </div>
        <div className='PrizeCards__card_content'>
          {prize.content}
        </div>
        {form ? (
          <Form prizeName={prize.name} locked={locked} />
        ) : (
        <button onClick={handleSelect.bind(null, prize.name)} className="PrizesPage__form-button">
          {translate("en-CA", "client.prizes.selectButton")}
        </button>
        )}
      </div>
    )
  }

  const renderPrizes = () => {
    if(selectedPrize !== null) {
      return renderPrize(prizeDetails.find(({name})=>name === selectedPrize), true);
    }

    return prizeDetails.map((prize)=>{
      return renderPrize(prize)
    })
  }


  return (
    <div className='PrizeCards'>
      {renderPrizes()}
    </div>
  )
}
