import React from 'react'
import I18n from './helpers/I18n';
import logo from '../assets/crave_logo.svg';
import close from '../assets/x_icon.svg';


export const PrizeRules = ({ handleClose }) => {
  const rulesList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return (
    <div className='PrizeRules'>
      <div className='PrizeRules__head'>
        <img
          className="PrizeRules__head_logo"
          src={logo}
          alt="crave_logo"
        />
        <img className='PrizeRules__head_close'
          onClick={handleClose}
          src={close}
        />
      </div>
      <div className='PrizeRules__body'>
        <div className='PrizeRules__body_title'>
          <I18n t="client.rules.title" />
        </div>
        <div className='PrizeRules__body_subtitle'>
          <I18n t="client.rules.subtitle" />
        </div>
        <div className='PrizeRules__body_content'>
          <ol>
            {rulesList.map((key) => {
              return (
                <>
                  <div className='PrizeRules__body_content_title' >
                    <I18n t={`client.rules.content.${key}.title`} />
                  </div>
                  <div className='PrizeRules__body_content_item'>
                    <I18n t={`client.rules.content.${key}.content`} />
                  </div>
                  {key === 9 && (rulesList.map((k) => {
                    return (
                      <div className='PrizeRules__body_content_end'>
                        <I18n t={`client.rules.content.9.list.${k}`} />
                      </div>
                    )
                  }))}
                </>
              )
            }
            )}
          </ol>
        </div>
      </div>


    </div>
  )
}

